.contents[data-v-7c863cae] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.contents span[data-v-7c863cae] {
  line-height: 28px;
  font-size: 14px;
}
.contents tr[data-v-7c863cae] {
  padding: 0;
  margin: 0;
}
.contents td[data-v-7c863cae] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 14px;
}
.contents input[data-v-7c863cae] {
  width: 100%;
  line-height: 25px;
  padding: 0 6px;
  font-size: 14px;
  text-align: right;
  padding-right: 12px;
}
.top_title[data-v-7c863cae] {
  width: 100%;
  margin: 0 auto;
}
.top_title h5[data-v-7c863cae] {
  font-size: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 36px;
}
.top_input[data-v-7c863cae] {
  width: 30%;
}
.top_input h6[data-v-7c863cae] {
  float: left;
  width: 60px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
}
.top_input2[data-v-7c863cae] {
  width: 30%;
  margin: 0 auto;
  position: relative;
}
.top_input2 h6[data-v-7c863cae] {
  float: left;
  width: 60px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 28px;
  margin-right: 10px;
}
.top_input2 input[data-v-7c863cae] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.bottom_num[data-v-7c863cae] {
  width: 20%;
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
}
.star[data-v-7c863cae] {
  color: #f15a24;
  height: 12px;
  font-size: 18px;
  display: inline-block;
  z-index: 999;
}